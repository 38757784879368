import React, { useRef, useLayoutEffect, useEffect, useState } from "react";
import PropTypes from "prop-types";

import {
  StyledBackground,
  StyledSVGContainer,
  StyledSVG,
  StyledBeginScreenContainer,
} from "./index.styled";

import { useResize } from "../../../hooks";

const BeginScreen = ({ backgroundUrl, top, left, setEnterRoom }) => {
  const ref = useRef();
  const [dragState, setDrag] = useState({
    isDrag: false,
    start: 0,
    trans: 0,
    full: true,
    width: 0,
  });

  const { isMobile } = useResize();
  // console.log(left)
  // console.log(top)

  useEffect(() => {
    // setDrag({
    //   ...dragState,
    //   trans: -(1000 - window.innerWidth) / 2,
    // });

    if (window.innerWidth < 1200) {
      setDrag({
        ...dragState,
        width: 0,
        full: false,
        trans: 0,
      });
    } else {
      setDrag({
        ...dragState,
        trans: 0,
        full: true,
      });
    }
  }, [backgroundUrl, window.innerWidth]);

  const onImgLoad = ({ target: img }) => {
    console.log("onImgLoad", img, img.naturalWidth, img.naturalHeight);

    const calcWidth = img.naturalWidth * window.innerHeight / img.naturalHeight
    setTimeout(() => {
      if (window.innerWidth < 1200) {
        setDrag({
          ...dragState,
          trans: (-(calcWidth - window.innerWidth) * 2) / 3,
          width: calcWidth,
          full: false,
        });
      } else {
        setDrag({
          ...dragState,
          trans: 0,
          full: true,
        });
      }
    }, 200);
  };

  const onTouchStart = (e) => {
    console.log("onTouchStart");

    if (!dragState.full) {
      setDrag({
        ...dragState,
        isDrag: true,
        start: e.touches[0].clientX,
      });
    }
  };

  const onTouchMove = (e) => {
    if (dragState.isDrag) {
      const trans = dragState.trans + (e.touches[0].clientX - dragState.start);
      const offset = ref.current.offsetWidth - window.innerWidth;

      if (offset > trans * -1 && trans < 0) {
        setDrag({
          ...dragState,
          start: e.touches[0].clientX,
          trans: trans,
        });
      }
    }
  };

  const onTouchEnd = (e) => {
    setDrag({
      ...dragState,
      isDrag: false,
    });
  };

  const onMouseDown = (e) => {
    console.log("onMouseDown", e.clientX, e);

    if (!dragState.full) {
      setDrag({
        ...dragState,
        isDrag: true,
        start: e.clientX,
      });
    }
  };

  const onMouseMove = (e) => {
    if (dragState.isDrag) {
      const trans = dragState.trans + (e.clientX - dragState.start);
      const offset = ref.current.offsetWidth - window.innerWidth;

      if (offset > trans * -1 && trans < 0) {
        setDrag({
          ...dragState,
          start: e.clientX,
          trans: trans,
        });
      }
    }
  };

  const onMouseUp = (e) => {
    setDrag({
      ...dragState,
      isDrag: false,
    });
  };

  return (
    <StyledBackground
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
      onTouchMove={onTouchMove}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      full={dragState.full}
    >
      <StyledBeginScreenContainer
        style={{
          width: dragState.full ? "100%" : dragState.width,
          transform: `translateX(${dragState.trans}px)`,
          position: "relative",
          opacity: dragState.full ? "1.0" : dragState.width === 0 ? "0.0" : "1.0"
        }}
        // trans={dragState.trans}
        // full={dragState.full}
        // style={{ width: dragState.full ? "100%" : "auto" }}
      >
        <img src={backgroundUrl} onLoad={onImgLoad} ref={ref} />
        <StyledSVGContainer
          // left={left}
          // top={top}
          style={{
            position: "absolute",
            left: `${left}`,
            top: `${top}`,
          }}
          onClick={() => {
            setEnterRoom();
          }}
        >
          <StyledSVG
            xmlns="http://www.w3.org/2000/svg"
            width="85.821"
            height="85.821"
            viewBox="0 0 85.821 85.821"
          >
            <circle className="a" cx="42.911" cy="42.911" r="42.911" />
            <path
              className="b"
              d="M27.51,0A27.424,27.424,0,1,1,16.8,2.162,27.423,27.423,0,0,1,27.51,0Z"
              transform="translate(15.404 15.401)"
            />
            <g transform="translate(35.913 32.649)">
              <g className="c">
                <path className="e" d="M7,0l7,14H0Z" />
                <path
                  className="f"
                  d="M 7.000000953674316 2.236069679260254 L 1.618036270141602 13 L 12.38196563720703 13 L 7.000000953674316 2.236069679260254 M 7.000000953674316 0 L 14.00000095367432 14 L 9.5367431640625e-07 14 L 7.000000953674316 0 Z"
                />
              </g>
              <line
                className="d"
                y2="7"
                transform="translate(3.5 16.5) rotate(-90)"
              />
              <line
                className="d"
                y2="3"
                transform="translate(5.5 18.5) rotate(-90)"
              />
            </g>
          </StyledSVG>
        </StyledSVGContainer>
      </StyledBeginScreenContainer>
    </StyledBackground>
  );
};

BeginScreen.propTypes = {};

export default BeginScreen;
