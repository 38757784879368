import React from 'react'

const HelpIcon = React.memo(() => {
  return (
    <svg id="help" xmlns="http://www.w3.org/2000/svg" width="9.982" height="16.353" viewBox="0 0 9.982 16.353">
      <path id="help-2" data-name="help" d="M4.278-4.922A.266.266,0,0,1,4.083-5,.266.266,0,0,1,4-5.2v-.943a2.736,2.736,0,0,1,.552-1.782,8.362,8.362,0,0,1,1.61-1.437,8.01,8.01,0,0,0,1.38-1.208A2,2,0,0,0,8-11.914a1.811,1.811,0,0,0-.621-1.437,2.4,2.4,0,0,0-1.633-.541,2.46,2.46,0,0,0-1.7.575A1.885,1.885,0,0,0,3.4-11.845v.483q0,.253-.276.253L1.012-11.2a.251.251,0,0,1-.2-.092.306.306,0,0,1-.08-.207v-.345A4.291,4.291,0,0,1,1.38-14.18a4.3,4.3,0,0,1,1.794-1.587,5.938,5.938,0,0,1,2.645-.564,5.172,5.172,0,0,1,3.565,1.185,3.993,3.993,0,0,1,1.334,3.139,3.973,3.973,0,0,1-.357,1.748,4.223,4.223,0,0,1-.828,1.2A11.088,11.088,0,0,1,8.257-8.027a7.931,7.931,0,0,0-1.2,1A1.489,1.489,0,0,0,6.67-5.98V-5.2a.266.266,0,0,1-.081.2.266.266,0,0,1-.2.081ZM5.267.023a1.589,1.589,0,0,1-1.173-.46,1.589,1.589,0,0,1-.46-1.173,1.551,1.551,0,0,1,.46-1.162A1.611,1.611,0,0,1,5.267-3.22a1.572,1.572,0,0,1,1.161.448A1.572,1.572,0,0,1,6.877-1.61a1.589,1.589,0,0,1-.46,1.173A1.56,1.56,0,0,1,5.267.023Z" transform="translate(-0.736 16.33)" fill="#f2f2f2" />
    </svg>

  )
})

export { HelpIcon }