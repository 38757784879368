import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import * as THREE from "three";

import Sphere from "./Sphere";

const RoomSphere = ({
  data,
  pointId,
  prevPointId,
  setCurrentPoint,
  onAnimationEnd,
  onPointerDown,
  removeSphere
}) => {
  const p = _.get(data, pointId, null);
  const prevPoint = _.get(data, prevPointId);
  const sphereRotation = _.get(p, "sphereRotation", [0, 0, 0]);
  const prevSphereRotation = _.get(prevPoint, "sphereRotation", [0, 0, 0]);

  const spherePosition = _.get(p, "position", [0, 0]);
  const prevSpherePosition = _.get(prevPoint, "position", [0, 0]);

  const x = (prevSpherePosition[0] - spherePosition[0]) * 0.7;
  const y = 0;
  const z = (prevSpherePosition[1] - spherePosition[1]) * 0.7;

  const dx = x * 2;
  const dy = y * 2;
  const dz = z * 2;

  return (
    <group>
      {prevPoint && (
        <Sphere
          key={"back" + prevPoint.id}
          pointId={prevPoint.id}
          rotation={_.map(prevSphereRotation, THREE.MathUtils.degToRad)}
          texture={_.get(prevPoint, "texture")}
          offset={[-dx, dy, -dz]}
          visible={false}
          opacity={1.0}
          onPointerDown={onPointerDown}
          removeSphere={removeSphere}
        />
      )}
      <Sphere
        key={"front" + p.id}
        texture={_.get(p, "texture")}
        rotation={_.map(sphereRotation, THREE.MathUtils.degToRad)}
        setCurrentPoint={setCurrentPoint}
        offset={[dx, dy, dz]}
        pointId={p.id}
        opacity={0.0}
        onAnimationEnd={onAnimationEnd}
        visible
        onPointerDown={onPointerDown}
      />
    </group>
  );
};

RoomSphere.propTypes = {};

export default RoomSphere;
