import React, { useMemo } from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import { Html } from "@react-three/drei";

// Components
import {
  StyledHotspot,
  StyledSVG,
  StyledContent,
  visible,
} from "./index.styled";

const StartSpot = ({ sphereData, pointId, goStartScene, visible }) => {
  const spot = useMemo(
    () => _.get(sphereData, [pointId, "startSpot"], null),
    [sphereData, pointId]
  );

  console.log(spot, pointId);
  return (
    <>
      {!spot && null}
      {spot && (
        <mesh position={spot}>
          <Html
            center
            sprite
            zIndexRange={[3, 0]}
            style={{ opacity: visible ? 1 : 0 }}
          >
            <StyledHotspot className="start-spot" onClick={goStartScene}>
              <StyledSVG
                xmlns="http://www.w3.org/2000/svg"
                width="85.821"
                height="85.821"
                viewBox="0 0 85.821 85.821"
              >
                <circle className="a" cx="42.911" cy="42.911" r="42.911" />
                <path
                  className="b"
                  d="M27.51,0A27.424,27.424,0,1,1,16.8,2.162,27.423,27.423,0,0,1,27.51,0Z"
                  transform="translate(15.404 15.401)"
                />
                <g transform="translate(35.913 32.649)">
                  <g className="c">
                    <path className="e" d="M7,0l7,14H0Z" />
                    <path
                      className="f"
                      d="M 7.000000953674316 2.236069679260254 L 1.618036270141602 13 L 12.38196563720703 13 L 7.000000953674316 2.236069679260254 M 7.000000953674316 0 L 14.00000095367432 14 L 9.5367431640625e-07 14 L 7.000000953674316 0 Z"
                    />
                  </g>
                  <line
                    className="d"
                    y2="7"
                    transform="translate(3.5 16.5) rotate(-90)"
                  />
                  <line
                    className="d"
                    y2="3"
                    transform="translate(5.5 18.5) rotate(-90)"
                  />
                </g>
              </StyledSVG>
            </StyledHotspot>
          </Html>
        </mesh>
      )}
    </>
  );
};

StartSpot.propTypes = {};

export default StartSpot;
