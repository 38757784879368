import styled from 'styled-components'

export const StyledBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  // pointer-events: none;
  user-select: none;

  img {
    width: ${(props) => props.full ? "100%" : 'auto'};
    height: 100%;
    // position: absolute;
    // left: 0;
    // top: 0;
    pointer-events: none;
    user-select: none;
  }
`

export const StyledBeginScreenContainer = styled.div`
  position: relative;
  // width: ${(props) => props.full ? "100%" : 'auto'};
  // transform: translateX(${(props) => props.trans}px);
`

export const StyledSVGContainer = styled.div`
  position: absolute;
  // left: ${(props) => props.left};
  // top: ${(props) => props.top};

  &:hover {
    cursor: pointer;
  }
`
export const StyledSVG = styled.svg`
  .a {
    fill:#fff;
    opacity:0.4;
  }

  .b {
    fill:#0166a4;
    opacity:0.8;
  }

  .c, .d {
    fill:none;
  }

  .d {
    stroke:#fff;
  }

  .e, .f {
    stroke:none;
  }

  .f {
    fill:#fff;
  }
`

