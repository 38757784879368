import styled from "styled-components";

export const StyledHotspot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`

export const StyledSVG = styled.svg`
  .a {
    fill:#fff;
    opacity:0.4;
  }

  .b {
    fill:#0166a4;
    opacity:0.8;
  }

  .c, .d {
    fill:none;
  }

  .d {
    stroke:#fff;
  }

  .e, .f {
    stroke:none;
  }

  .f {
    fill:#fff;
  }
`