import React from 'react'

const ShareIcon = React.memo(() => {
  return (
    <svg id="Component_8_63" data-name="Component 8 – 63" xmlns="http://www.w3.org/2000/svg" width="20" height="25" viewBox="0 0 20 25">
      <path id="share" d="M13.75-5a3.616,3.616,0,0,1,2.656,1.094A3.616,3.616,0,0,1,17.5-1.25a3.616,3.616,0,0,1-1.094,2.656A3.616,3.616,0,0,1,13.75,2.5a3.616,3.616,0,0,1-2.656-1.094A3.616,3.616,0,0,1,10-1.25a4.1,4.1,0,0,1,.156-1.133L6.328-4.766A3.664,3.664,0,0,1,3.75-3.75,3.616,3.616,0,0,1,1.094-4.844,3.616,3.616,0,0,1,0-7.5a3.616,3.616,0,0,1,1.094-2.656A3.616,3.616,0,0,1,3.75-11.25a3.664,3.664,0,0,1,2.578,1.016l3.828-2.383A4.1,4.1,0,0,1,10-13.75a3.616,3.616,0,0,1,1.094-2.656A3.616,3.616,0,0,1,13.75-17.5a3.616,3.616,0,0,1,2.656,1.094A3.616,3.616,0,0,1,17.5-13.75a3.616,3.616,0,0,1-1.094,2.656A3.616,3.616,0,0,1,13.75-10a3.664,3.664,0,0,1-2.578-1.016L7.344-8.633a3.738,3.738,0,0,1,0,2.266l3.828,2.383A3.664,3.664,0,0,1,13.75-5Zm1.328-10.078a1.808,1.808,0,0,0-1.328-.547,1.808,1.808,0,0,0-1.328.547,1.808,1.808,0,0,0-.547,1.328,1.808,1.808,0,0,0,.547,1.328,1.808,1.808,0,0,0,1.328.547,1.808,1.808,0,0,0,1.328-.547,1.808,1.808,0,0,0,.547-1.328A1.808,1.808,0,0,0,15.078-15.078ZM2.422-6.172a1.808,1.808,0,0,0,1.328.547,1.808,1.808,0,0,0,1.328-.547A1.808,1.808,0,0,0,5.625-7.5a1.808,1.808,0,0,0-.547-1.328A1.808,1.808,0,0,0,3.75-9.375a1.808,1.808,0,0,0-1.328.547A1.808,1.808,0,0,0,1.875-7.5,1.808,1.808,0,0,0,2.422-6.172Zm10,6.25A1.808,1.808,0,0,0,13.75.625,1.808,1.808,0,0,0,15.078.078a1.808,1.808,0,0,0,.547-1.328,1.808,1.808,0,0,0-.547-1.328,1.808,1.808,0,0,0-1.328-.547,1.808,1.808,0,0,0-1.328.547,1.808,1.808,0,0,0-.547,1.328A1.808,1.808,0,0,0,12.422.078Z" transform="translate(0 22)" fill="#f2f2f2"/>
    </svg>
  )
})

export {ShareIcon}