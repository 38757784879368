// Bicycle
import Bicycle1 from "./Bicycle/bicycle20000.jpg";
import Bicycle2 from "./Bicycle/bicycle300000.jpg";
import Bicycle3 from "./Bicycle/bicycle200000.jpg";

// Bobike
import Bobike1 from "./Bobike/bobikeexterior.png";
import Bobike2 from "./Bobike/bobike40000.png";
import Bobike3 from "./Bobike/bobike50000.jpg";

// Catlike
import Catlike1 from "./CATLIKE/catlikeexterior0000.png";
import Catlike2 from "./CATLIKE/catlike130000.png";
import Catlike3 from "./CATLIKE/catlike140000.png";
import Catlike4 from "./CATLIKE/catlike150000.png";
import Catlike5 from "./CATLIKE/catlike160000.png";
import Catlike6 from "./CATLIKE/catlike170000.png";

// Offroad
import Offroad1 from "./Offroad/offroadexterior0000.jpg";
import Offroad2 from "./Offroad/offroad70000.jpg";
import Offroad3 from "./Offroad/offroad80000.jpg";
import Offroad4 from "./Offroad/offroad90000.jpg";
import Offroad5 from "./Offroad/offroad100000.jpg";
import Offroad6 from "./Offroad/offroad110000.jpg";
import Offroad7 from "./Offroad/offroad250000.jpg";
import Offroad8 from "./Offroad/offroad260000.jpg";

// orignal position
// polistar-7

// Videos
import video1 from "../videos/spot-catlike-final.ogv";

export default {
  bicycle: {
    preview: {
      id: 0,
      url: Bicycle1,
      type: "preview",
      left: "40%",
      bottom: "60%",
      infospots: [],
    },
    sphereData: [
      {
        id: 1,
        url: Bicycle3,
        type: "360",
        position: [-34, -400],
        sphereRotation: [0, 120, 0],
        startSpot: [100, 0, 200],
        infospots: [
          {
            position: [-100, -35, -30],
            size: [6, 6],
            title: "GROOVY CFS",
            link: "/products/bicycle/4",
          },
          {
            position: [-200, -50, -130],
            size: [12, 12],
            title: "GUPPY MINI",
            link: "/products/bicycle/3",
          },
          {
            position: [-200, -20, -330],
            size: [20, 20],
            title: "BALOONS HELMET",
            link: "/products/bicycle/2",
          },
          {
            position: [-160, -20, -330],
            size: [20, 20],
            title: "COMMUTER",
            link: "/products/bicycle/1",
          },
          {
            position: [-110, -40, -330],
            size: [20, 20],
            title: "BALANCE BIKE",
            link: "/products/bicycle/9",
          },
          {
            position: [13, -40, -60],
            size: [5, 5],
            title: "TRAILER",
            link: "/products/bicycle/8",
          },
        ],
        hotspots: [
          {
            position: [30, 0, 260],
            size: [30, 30],
            link: 2,
            rotation: [0, 0, 0],
          },
        ],
      },
      {
        id: 2,
        url: Bicycle2,
        type: "360",
        position: [0, 0],
        sphereRotation: [0, 90, 0],
        infospots: [
          {
            position: [-195, -30, -130],
            size: [10, 10],
            title: "BUNDLE KIT",
            link: "/products/bicycle/7",
          },
          {
            position: [-150, -30, -130],
            size: [10, 10],
            title: "T500",
            link: "/products/bicycle/6",
          },
          {
            position: [-60, -30, -130],
            size: [10, 10],
            title: "LIGHT PRO",
            link: "/products/bicycle/5",
          },
          {
            position: [100, -30, -130],
            size: [10, 10],
            title: "MUD-PRO",
            link: "/products/bicycle/10",
          },
          {
            position: [-80, 0, 150],
            size: [10, 10],
            title: "BALANCE BIKE",
            link: "/products/bicycle/9",
          },
          {
            position: [80, -80, 200],
            size: [20, 20],
            title: "TRAILER",
            link: "/products/bicycle/8",
          },
        ],
        hotspots: [
          {
            position: [0, 0, -260],
            size: [30, 30],
            link: 1,
            rotation: [0, 0, 0],
          },
        ],
      },
    ],
  },
  bobike: {
    preview: {
      id: 0,
      url: Bobike1,
      type: "preview",
      left: "50%",
      bottom: "70%",
      infospots: [],
    },
    sphereData: [
      {
        id: 1,
        url: Bobike2,
        type: "360",
        position: [0, 0],
        sphereRotation: [0, 90, 0],
        startSpot: [0, 0, 60],
        infospots: [
          {
            position: [-22, -12, -65],
            size: [5, 5],
            title: "GO MINI",
            link: "/products/bobike/6",
          },
          {
            position: [8, -17, -65],
            size: [5, 5],
            title: "GO MAX",
            link: "/products/bobike/5",
          },
          {
            position: [40, -10, -65],
            size: [5, 5],
            title: "ONE MINI",
            link: "/products/bobike/9",
          },
          {
            position: [45, 12, -65],
            size: [5, 5],
            title: "PLUS HELMET XS",
            link: "/products/bobike/10",
          },
          {
            position: [65, -18, -65],
            size: [5, 5],
            title: "ONE MAXI",
            link: "/products/bobike/8",
          },
          {
            position: [95, -25, -65],
            size: [5, 5],
            title: "EXCLUSIVE MAXI",
            link: "/products/bobike/2",
          },
          {
            position: [140, 15, -65],
            size: [7, 7],
            title: "EXCLUSIVE TOUR",
            link: "/products/bobike/4",
          },
          {
            position: [140, -35, -45],
            size: [7, 7],
            title: "EXCLUSIVE MINI",
            link: "/products/bobike/3",
          },
        ],
        hotspots: [
          {
            position: [160, 0, 200],
            size: [30, 30],
            link: 2,
            rotation: [0, 0, 0],
          },
        ],
      },
      {
        id: 2,
        url: Bobike3,
        type: "360",
        position: [250, 300],
        sphereRotation: [0, 90, 0],
        infospots: [
          {
            position: [60, -20, -20],
            size: [5, 5],
            title: "KIDS PLUS HELMET S",
            link: "/products/bobike/7",
          },
          {
            position: [60, -30, 40],
            size: [5, 5],
            title: "BALANCE BIKE",
            link: "/products/bobike/1",
          },
        ],
        hotspots: [
          {
            position: [-60, 0, -200],
            size: [30, 30],
            link: 1,
            rotation: [0, 0, 0],
          },
        ],
      },
    ],
  },
  offroad: {
    preview: {
      id: 0,
      url: Offroad1,
      type: "preview",
      left: "50%",
      bottom: "70%",
      infospots: [],
    },
    sphereData: [
      {
        id: 1,
        url: Offroad8,
        type: "360",
        position: [0, 0],
        sphereRotation: [0, 125, 0],
        startSpot: [100, 0, 100],
        infospots: [
          {
            position: [-120, -60, -180],
            size: [20, 20],
            title: "FORTRESS SKID PLATE",
            link: "/products/offroad/4",
          },
          {
            position: [-60, -60, -180],
            size: [20, 20],
            title: "PROOCTANE Utility Can",
            link: "/products/offroad/7",
          },
        ],
        hotspots: [
          {
            position: [220, 0, 200],
            size: [30, 30],
            link: 3,
            rotation: [0, 0, 0],
          },
        ],
      },
      {
        id: 2,
        url: Offroad2,
        type: "360",
        position: [250, 300],
        sphereRotation: [0, 90, 0],
        infospots: [
          {
            position: [-180, -40, -300],
            size: [20, 20],
            title: "SILENCER PROTECTOR",
            link: "/products/offroad/9",
          },
          {
            position: [-250, -60, -200],
            size: [20, 20],
            title: "DISC & BOTTOM FORK GUARDS",
            link: "/products/offroad/3",
          },
          {
            position: [-350, -100, -200],
            size: [20, 20],
            title: "CLUTCH & IGNITION PROTECTOR",
            link: "/products/offroad/2",
          },
          {
            position: [200, -100, -200],
            size: [20, 20],
            title: "RESTYLING KITS",
            link: "/products/offroad/8",
          },
        ],
        hotspots: [
          {
            position: [250, 0, 100],
            size: [25, 25],
            link: 5,
            rotation: [0, 0, 0],
          },
          {
            position: [-250, 0, 100],
            size: [25, 25],
            link: 6,
            rotation: [0, 0, 0],
          },
          {
            position: [250, 0, -150],
            size: [25, 25],
            link: 4,
            rotation: [0, 0, 0],
          },
          {
            position: [-120, 10, -100],
            size: [15, 15],
            link: 3,
            rotation: [0, 0, 0],
          }
        ],
      },
      {
        id: 3,
        url: Offroad3,
        type: "360",
        position: [120, 130],
        sphereRotation: [0, 90, 0],
        infospots: [
          {
            position: [-30, -70, -300],
            size: [20, 20],
            title: "RESTYLING KITS",
            link: "/products/offroad/8",
          },
          {
            position: [130, -70, 80],
            size: [20, 20],
            title: "FORTRESS SKID PLATE",
            link: "/products/offroad/4",
          },
          {
            position: [150, -70, -40],
            size: [20, 20],
            title: "PROOCTANE UTILITY Can",
            link: "/products/offroad/7",
          },
        ],
        hotspots: [
          {
            position: [100, 0, 200],
            size: [25, 25],
            link: 2,
            rotation: [0, 0, 0],
          },
          {
            position: [-70, 0, 200],
            size: [25, 25],
            link: 6,
            rotation: [0, 0, 0],
          },
          {
            position: [-70, 0, -100],
            size: [15, 15],
            link: 1,
            rotation: [0, 0, 0],
          },
          {
            position: [130, 10, 10],
            size: [15, 15],
            link: 4,
            rotation: [0, 0, 0],
          },
        ],
      },
      {
        id: 4,
        url: Offroad4,
        type: "360",
        position: [400, 130],
        sphereRotation: [0, 90, 0],
        infospots: [
          {
            position: [-30, -50, -300],
            size: [20, 20],
            title: "CLUTCH & IGNITION PROTECTOR",
            link: "/products/offroad/2",
          },
          {
            position: [40, -50, -300],
            size: [20, 20],
            title: "DISC & BOTTOM FORK GUARDS",
            link: "/products/offroad/3",
          },
          {
            position: [110, -30, -300],
            size: [20, 20],
            title: "SILENCER PROTECTOR",
            link: "/products/offroad/9",
          },
          {
            position: [-100, -60, -20],
            size: [20, 20],
            title: "TRACK STAND",
            link: "/products/offroad/10",
          },
          {
            position: [-120, -50, 70],
            size: [20, 20],
            title: "ARMADILLO PIPE GUARD",
            link: "/products/offroad/1",
          },
        ],
        hotspots: [
          {
            position: [100, 0, -100],
            size: [15, 15],
            link: 7,
            rotation: [0, 0, 0],
          },
          {
            position: [70, 0, 200],
            size: [25, 25],
            link: 5,
            rotation: [0, 0, 0],
          },
          {
            position: [-110, 0, 200],
            size: [25, 25],
            link: 2,
            rotation: [0, 0, 0],
          },
          {
            position: [-250, 10, 0],
            size: [25, 25],
            link: 2,
            rotation: [0, 0, 0],
          },
        ],
      },
      {
        id: 5,
        url: Offroad5,
        type: "360",
        position: [500, 470],
        sphereRotation: [0, 0, 0],
        infospots: [
          {
            position: [150, -35, -30],
            size: [20, 20],
            title: "GRAPHIC GUARDS PROTECTOR",
            link: "/products/offroad/6",
          },
        ],
        hotspots: [
          {
            position: [-100, 0, -100],
            size: [15, 15],
            link: 2,
            rotation: [0, 0, 0],
          },
          {
            position: [-30, 0, -100],
            size: [10, 10],
            link: 4,
            rotation: [0, 0, 0],
          },
        ],
      },
      {
        id: 6,
        url: Offroad6,
        type: "360",
        position: [30, 400],
        sphereRotation: [0, 180, 0],
        infospots: [
          {
            position: [-150, -50, -30],
            size: [20, 20],
            title: "FRONT SPROCKET PROTECTOR",
            link: "/products/offroad/5",
          },
        ],
        hotspots: [
          {
            position: [80, 0, -170],
            size: [20, 20],
            link: 3,
            rotation: [0, 0, 0],
          },
          {
            position: [170, 0, -170],
            size: [20, 20],
            link: 2,
            rotation: [0, 0, 0],
          },
        ],
      },
      {
        id: 7,
        url: Offroad7,
        type: "360",
        position: [500, 0],
        sphereRotation: [0, 50, 0],
        infospots: [
          {
            position: [120, -100, -200],
            size: [20, 20],
            title: "ARMADILLO PIPE GUARD",
            link: "/products/offroad/1",
          },
          {
            position: [60, -100, -250],
            size: [20, 20],
            title: "TRACK STAND",
            link: "/products/offroad/10",
          },
        ],
        hotspots: [
          {
            position: [-130, 0, 130],
            size: [20, 20],
            link: 4,
            rotation: [0, 0, 0],
          },
        ],
      },
    ],
  },
  catlike: {
    preview: {
      id: 0,
      url: Catlike1,
      type: "preview",
      left: "50%",
      bottom: "70%",
      infospots: [],
    },
    video: {
      src: video1,
    },
    sphereData: [
      {
        id: 1,
        url: Catlike3,
        type: "360",
        position: [-230, -400],
        sphereRotation: [0, 90, 0],
        startSpot: [20, 0, 60],
        infospots: [],
        videos: [
          {
            position: [-60, -49, -380],
            rotation: [0, 0, 0],
            size: [170, 115],
          },
        ],
        hotspots: [
          {
            position: [40, 0, 100],
            size: [10, 10],
            link: 2,
            rotation: [0, 0, 0],
          },
          {
            position: [-50, 0, 260],
            size: [25, 25],
            link: 5,
            rotation: [0, 0, 0],
          },
        ],
      },
      {
        id: 2,
        url: Catlike2,
        type: "360",
        position: [0, 0],
        sphereRotation: [0, 90, 0],
        infospots: [
          {
            position: [-240, -65, -130],
            size: [20, 20],
            title: "MIXINO",
            link: "/products/catlike/3",
          },
          {
            position: [-175, -35, -160],
            size: [20, 20],
            title: "WHISPER EVO",
            link: "/products/catlike/5",
          },
          {
            position: [-95, -50, -230],
            size: [20, 20],
            title: "KOMPACTO",
            link: "/products/catlike/2",
          },
        ],
        videos: [
          {
            position: [130, -50, -140],
            rotation: [0, 0, 0],
            size: [170, 115],
          },
        ],
        hotspots: [
          {
            position: [-250, 0, 100],
            size: [30, 30],
            link: 5,
            rotation: [0, 0, 0],
          },
          {
            position: [-160, 0, -130],
            size: [25, 25],
            link: 1,
            rotation: [0, 0, 0],
          },
          {
            position: [200, 0, -130],
            size: [25, 25],
            link: 3,
            rotation: [0, 0, 0],
          },
          {
            position: [250, 0, 100],
            size: [25, 25],
            link: 4,
            rotation: [0, 0, 0],
          },
        ],
      },
      {
        id: 3,
        url: Catlike4,
        type: "360",
        position: [230, -400],
        sphereRotation: [0, 90, 0],
        infospots: [
          {
            position: [-3, -20, -130],
            size: [5, 5],
            title: "MIXINO",
            link: "/products/catlike/3",
          },
          {
            position: [30, -10, -130],
            size: [5, 5],
            title: "WHISPER EVO",
            link: "/products/catlike/5",
          },
          {
            position: [55, -10, -130],
            size: [5, 5],
            title: "KOMPACTO",
            link: "/products/catlike/2",
          },
        ],
        hotspots: [
          {
            position: [-250, 0, 0],
            size: [30, 30],
            link: 1,
            rotation: [0, 0, 0],
          },
          {
            position: [-100, -50, 250],
            size: [30, 30],
            link: 2,
            rotation: [0, 0, 0],
          },
          {
            position: [50, 0, 250],
            size: [30, 30],
            link: 4,
            rotation: [0, 0, 0],
          }
        ]
      },
      {
        id: 4,
        url: Catlike5,
        type: "360",
        position: [300, 130],
        sphereRotation: [0, 0, 0],
        infospots: [
          {
            position: [150, 15, -110],
            size: [10, 10],
            title: "WHISPER OVAL CARBON ROAD",
            link: "/products/catlike/7",
          },
          {
            position: [140, 15, 80],
            size: [10, 10],
            title: "WHISPER OVAL CARBON MTB",
            link: "/products/catlike/6",
          },
        ],
        hotspots: [
          {
            position: [-200, 0, -200],
            size: [30, 30],
            link: 2,
            rotation: [0, 0, 0],
          },
          {
            position: [-10, 0, -200],
            size: [30, 30],
            link: 2,
            rotation: [0, 0, 0],
          }
        ]
      },
      {
        id: 5,
        url: Catlike6,
        type: "360",
        position: [-300, 130],
        sphereRotation: [0, 180, 0],
        infospots: [
          {
            position: [-200, -40, 70],
            size: [20, 20],
            title: "KILAUEA",
            link: "/products/catlike/1",
          },
          {
            position: [-200, -40, -110],
            size: [20, 20],
            title: "VENTO",
            link: "/products/catlike/4",
          },
        ],
        hotspots: [
          {
            position: [70, 0, -200],
            size: [25, 25],
            link: 1,
            rotation: [0, 0, 0],
          },
          {
            position: [190, 0, -200],
            size: [30, 30],
            link: 2,
            rotation: [0, 0, 0],
          }
        ]
      },
    ],
  },
};

// export default {
//   'motorcycle-logistics-center' : {
//     sphereData: [
//       {
//         id: 0,
//         url: Bicycle1,
//         type: 'preview',
//         position: [0, 0],
//       },
//       {
//         id: 1,
//         url: Bicycle2,
//         type: '360',
//         position: [20, 60],
//         sphereRotation : [0, 90, 0]
//       },
//       {
//         id: 2,
//         url: Bicycle3,
//         type: '360',
//         position: [0, 0],
//         sphereRotation : [0, 120, 0]
//       }
//     ]
//   }
// }
