import React, { useState , useRef } from "react";
import PropTypes from "prop-types";
import * as THREE from "three";
import { useFrame } from "react-three-fiber";

import video1 from "../../../assets/videos/spot-catlike-final.mp4";

// import src from '../../../assets/videos/sintel.ogv';
const VideoScreen = ({ position, rotation, scale, video, visible }) => {
  // const [video] = useState(() => {
  //   const vid = document.createElement("video");
  //   vid.src = src;
  //   vid.crossOrigin = "Anonymous";
  //   vid.loop = true;
  //   vid.play();
  //   return vid;
  // });

  // const canvas = document.getElementById('dataCanvas');

  /** Solution 2 */
  // const [{context, canvas}] = useState(() => {
  //   const vid = document.createElement("canvas");
  //   vid.width = 270;
  //   vid.height = 230;

  //   const videoImageContext = vid.getContext( '2d' );

  //   return {context: videoImageContext, canvas: vid };
  // });

  // const videoTexture = new THREE.Texture(canvas)
  // videoTexture.minFilter = THREE.LinearFilter
  // videoTexture.magFilter = THREE.LinearFilter

  // useFrame(() => {
  //   if ( video.readyState === video.HAVE_ENOUGH_DATA ) {

  //     if (context) {
  //       context.drawImage(video, 0, 0)
  //     }

  //     if (videoTexture) {
  //       videoTexture.needsUpdate = true;
  //     }
  //   }
  // });

  return (
    <mesh
      position={position}
      rotation={rotation}
      renderOrder={1}
      visible={visible}
      // scale={[21, 19]}
    >
      {/* <Html center transform >
        <video id="canvas-video" loop crossOrigin="anonymous" preload={true} playsInline preload="true">
          <source src={video1} type='video/mp4'></source>
        </video>
      </Html> */}
      <planeBufferGeometry args={scale} />
      {/* <meshBasicMaterial
        color={0xff0000}
        side={THREE.DoubleSide}
        transparent={true}
      /> */}
      <meshBasicMaterial transparent={true}>
        <videoTexture attach="map" args={[video]} />
      </meshBasicMaterial>
      {/* <meshBasicMaterial attach="material" transparent={true} map={videoTexture} /> */}
    </mesh>
  );
};

VideoScreen.propTypes = {};

export default VideoScreen;
