import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import * as THREE from "three";
import * as _ from "lodash";
import { useThree } from "react-three-fiber";
// Components
import RoomSphere from "../room/RoomSphere";
import HotSpots from "../hotspots";
import InfoSpots from "../infospots";
import StartSpot from "../startspot";
import VideoCanvas from "../videocanvas";

const Tour = ({
  sphereData,
  pointId,
  videos,
  prevPointId,
  onPointClick,
  setHotSpotsVisible,
  hotSpotsVisible,
  onSelectProduct,
  goStartScene,
  setCursor,
  removeSphere
}) => {
  const { camera } = useThree();

  const hotSpots = useMemo(() => {
    const p = _.get(sphereData, pointId, null);
    // console.log(p)
    return _.map(
      _.filter(sphereData, (d) => d.id !== p.id),
      (d) => {
        const offsetx = d.position[0] - p.position[0];
        const offsety = -100;
        const offsetz = d.position[1] - p.position[1];

        return {
          link: d.id,
          type: d.type,
          position: [offsetx, offsety, offsetz],
          rotation: [1.5757, 0, 3.14],
          size: [20, 20, 20],
        };
      }
    );
  }, [pointId, sphereData]);

  const setCurrentPoint = (ev) => {
    const pos = ev.point;
    const rerpPos = pos.lerp(new THREE.Vector3(0, 0, 0), 0.1);
    const position = [rerpPos.x, rerpPos.y, rerpPos.z];
  };

  const onNewPointRendered = () => {
    setHotSpotsVisible(true);
  };

  const onPointerDown = (e) => {
    let min = 100000;
    let selectedHotSpot = null;

    var vector = new THREE.Vector3();
    camera.getWorldDirection(vector);

    const angleRadian = (Math.PI * (camera.fov / 2)) / 180;
    hotSpots
      .filter((h) => h.type === "360")
      .forEach((h) => {
        const hp = _.get(h, "position", [0, 0, 0]);
        const hpp = new THREE.Vector3(hp[0], hp[1], hp[2]);
        hpp.applyAxisAngle(new THREE.Vector3(0, 1, 0), Math.PI);

        const angle = new THREE.Vector3(hpp.x, 0, hpp.z).angleTo(
          new THREE.Vector3(vector.x, 0, vector.z)
        );
        
        if (angle < angleRadian) {
          const distance = hpp.distanceTo(new THREE.Vector3());
          if (distance < min) {
            min = distance;
            selectedHotSpot = h;
          }
        }
      });

    if (selectedHotSpot) onPointClick(selectedHotSpot.link);
  };

  return (
    <>
      <RoomSphere
        data={sphereData}
        pointId={pointId}
        prevPointId={prevPointId}
        setCurrentPoint={setCurrentPoint}
        onPointerDown={onPointerDown}
        onAnimationEnd={onNewPointRendered}
        removeSphere={removeSphere}
      />
      <HotSpots
        sphereData={sphereData}
        pointId={pointId}
        goPointClick={onPointClick}
        visible={hotSpotsVisible}
        setCursor={setCursor}
      />
      <InfoSpots 
        sphereData={sphereData}
        pointId={pointId}
        visible={hotSpotsVisible}
        onSelectProduct={onSelectProduct}
        setCursor={setCursor}
      />
      <StartSpot
        sphereData={sphereData}
        pointId={pointId}
        goStartScene={goStartScene}
        visible={hotSpotsVisible}
      />
      {videos && 
        <VideoCanvas
          sphereData={sphereData}
          pointId={pointId}
          videos={videos}
          visible={hotSpotsVisible}
        />
      }
    </>
  );
};

Tour.propTypes = {};

export default Tour;
