import React, { useMemo, useEffect, useState } from "react";
import * as _ from "lodash";

import PropTypes from "prop-types";

import VideoScreen from "./VideoScreen";

const VideoCanvas = ({ sphereData, pointId, videos, visible }) => {
  const videolists = useMemo(
    () => _.get(sphereData, [pointId, "videos"], []),
    [pointId, sphereData]
  );
  // const [texture, setTexture] = useState(null);

  const [video] = useState(() => {
    const src = _.get(videos, "src", null);
    const vid = document.getElementById("canvas-video");
    
    // vid.src = src;
    // vid.crossOrigin = "anonymous";
    // vid.loop = true;
    // console.log(vid);
    return vid;
  });

  useEffect(() => {
    return () => {
      if (video) video.pause();
    }
  }, [])

  // const videocontent = useMemo(() => {
  //   const src = _.get(videos, "src", null);
  //   const vid = document.createElement("video");
  //   vid.src = src;
  //   vid.crossOrigin = "Anonymous";
  //   vid.loop = true;
  //   vid.load();
  //   // vid.play();
  //   // vid.addEventListener('load', () => {
  //   //   console.log("load")
  //   // })
  //   vid.oncanplay = () => {
  //     // alert("Starting to load video");
  //     vid.play();
  //   };

  //   return vid;
  // }, [videos]);

  return (
    <>
      {_.map(videolists, (d, i) => (
        <VideoScreen
          key={i}
          position={_.get(d, "position", [0, 0, 0])}
          rotation={_.get(d, "rotation", [Math.PI / 2, 0, 0])}
          scale={_.get(d, "size", [50, 50])}
          video={video}
          visible={visible}
        />
      ))}
    </>
  );
};

VideoCanvas.propTypes = {};

export default VideoCanvas;
