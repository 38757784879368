import React, { useRef, useEffect, useState } from "react";
import { Html } from "@react-three/drei";
// import { useFrame } from "react-three-fiber";
import * as THREE from "three";

import { StyledHotspot, StyledSVG, StyledContent } from "./infospot.styled";

// Images
import { HotSpotTexture } from "../../../assets";

const InfoSpot = ({
  onSelectProduct,
  id,
  position,
  rotation,
  size,
  // texture,
  text,
  scale,
  link,
  visible,
  setCursor,
}) => {
  const [hover, setHover] = useState(false);
  const innerRef = useRef();
  // const outerRef = useRef();

  const [texture, setTexture] = useState(null);

  useEffect(() => {
    const loader = new THREE.TextureLoader();
    loader.load(HotSpotTexture, (t) => {
      setTexture(t);
    });
  }, []);

  // useFrame((state, delta) => {
  //   // innerRef.current.lookAt(new THREE.Vector3(0, 0, 0));
  //   // outerRef.current.lookAt(new THREE.Vector3(0, 0, 0));
  //   const time = state.clock.getElapsedTime();
  //   const scaleP = Math.sin(2 * time) * 0.5 + 1;
  //   innerRef.current.scale.x = scaleP;
  //   innerRef.current.scale.z = scaleP;
  //   innerRef.current.scale.y = scaleP;
  // });
  // useEffect(() => {
  //   console.log(texture)
  // }, [texture])

  return (
    <>
      {texture && (
        <sprite
          scale={size}
          position={position}
          renderOrder={2}
          visible={visible}
          onPointerUp={(e) => {
            // console.log(e)
            onSelectProduct(link);
            // setTimeout(() => {
            //   onSelectProduct(link);
            // }, 300)
          }}
          onPointerOver={() => {
            setCursor("pointer");
          }}
          onPointerOut={() => {
            setCursor("auto");
          }}
        >
          <spriteMaterial
            attach="material"
            map={texture}
            transparent
            opacity={0.8}
          />
        </sprite>
      )}
    </>
  );
};

export default InfoSpot;
