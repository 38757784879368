import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import * as THREE from "three";

// Images
import { WalkHotSpotTexture } from "../../../assets";

const HotMeshSpot = ({
  id,
  position,
  size,
  rotation,
  goPointClick,
  visible,
  setCursor,
  link,
}) => {
  const [texture, setTexture] = useState(null);

  useEffect(() => {
    const loader = new THREE.TextureLoader();
    loader.load(WalkHotSpotTexture, (t) => {
      setTexture(t);
    });
  }, []);

  return (
    <>
      {texture && (
        <sprite
          onPointerDown={() => {
            goPointClick(link);
          }}
          onPointerOver={() => {
            setCursor("pointer");
          }}
          onPointerOut={() => {
            setCursor("auto");
          }}
          renderOrder={2}
          scale={size}
          position={position}
          visible={visible}
        >
          <spriteMaterial attach="material" color={0xffffff} transparent map={texture} />
        </sprite>
      )}
    </>
  );
};

HotMeshSpot.propTypes = {};

export default HotMeshSpot;
