import React, { Suspense, useState, useEffect, useMemo } from "react";
import { useParams, useLocation } from "react-router";
import PropTypes from "prop-types";

import * as THREE from "three";

import {
  Header,
  MenuCorporate,
  FooterPage as Footer,
  Infos,
  HotSpot,
  VirtualTour,
} from "../../components";

import {
  Container,
  MenuContainer,
  SpotsContainer,
  SpotContainer,
} from "./index.styled";

import { LoadingPage } from "..";
import * as _ from "lodash";

import panoInfo from "../../assets/panos";

const Showrooms = () => {
  const [sphereData, setSphereData] = useState(null);

  const { id } = useParams();
  const pano = useMemo(() => _.get(panoInfo, id), [id]);

  const preview = _.get(pano, "preview", {});
  const sceneD = _.get(pano, "sphereData", []);
  const videoD = _.get(pano, "video", null);

  const loadTextures = async (urlField, chunkSize = 2) => {
    const chunks = _.chunk(sceneD, chunkSize);
    const amount = _.size(chunks);
    const res = [];

    for (const i in chunks) {
      const ch = chunks[i];
      const chPoints = await Promise.all(
        _.map(ch, async (p) => {
          const loader = new THREE.TextureLoader();
          const url = _.get(p, urlField);
          console.log(url);
          if (_.isNil(url)) {
            console.warn("no pano url", urlField, "point", p);
            return p;
          }
          const texture = await loader.loadAsync(_.get(p, urlField), (event) =>
            console.log("onProgress", event)
          );
          texture.minFilter = THREE.LinearFilter;
          texture.magFilter = THREE.LinearFilter;
          return { ...p, texture };
        })
      );
      const perc = (100 / amount) * (1 + _.toNumber(i));
      // setLoading(perc)
      res.push(chPoints);
    }

    return _.flatten(res);
  };

  useEffect(() => {
    const preLoad = async () => {
      const texturesLow = await loadTextures("url", 2);
      const newSphereData = _.keyBy(texturesLow, "id");
      console.log("texturesLow", texturesLow);
      setSphereData(newSphereData);
    };

    preLoad();
  }, [pano]);

  useEffect(() => {
    setSphereData(null);
  }, [id]);

  const url = useMemo(() => {
    if (id === "bicycle") {
      return "bicycle";
    } else if (id === "offroad") {
      return "offroad";
    } else if (id === "bobike") {
      return "bobike";
    } else if (id === "catlike") {
      return "catlike";
    }

    return null;
  }, [id]);

  return (
    <Suspense fallback={<LoadingPage redirect={false} />}>
      <Container>
        <Header color={url}></Header>
        {!sphereData && <LoadingPage redirect={false} />}
        {sphereData && (
          <VirtualTour
            sphereData={sphereData}
            preview={preview}
            videos={videoD}
            activeUrl={id}
          />
        )}
        <Footer className="dark" />
      </Container>
    </Suspense>
  );
};

Showrooms.propTypes = {};

export default Showrooms;
