import React, { useRef, useState, useMemo } from "react";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import { Canvas } from "react-three-fiber";
import * as _ from "lodash";

import { StyledContainer, StyledBottomLayer } from "./index.styled";

// Components
import PreviewTour from "./preview";
import BeginScreen from "./beginscreen";
import Tour from "./tours";
import CameraControls from "./controls/cameracontrol.js";
import Monitor from "./monitor";
import { useResize } from "../../hooks";

// import video1 from "../../assets/videos/spot-catlike-final.ogv";

import video1 from "../../assets/videos/spot-catlike-final.mp4";


export const isMobile = () => window.innerWidth < 811;

// import video1 from "../videos/spot-catlike-final.ogv";

const VirtualTour = ({ activeUrl, sphereData, preview, videos }) => {
  // const [pointId, setPointId] = useState(6)

  const history = useHistory();
  const { isMobile } = useResize();
  const [enterRoom, setEnterRoom] = useState(false);
  const [hotSpotsVisible, setHotSpotsVisible] = useState(false);
  const [cursor, setCursor] = useState("auto");

  const [pointInfo, setPointInfo] = useState({
    prevPointId: null,
    pointId: 1,
  });

  const onCreatedCanvas = (c) => {
    const { gl } = c;
  };

  const backgroundUrl = _.get(preview, "url");
  const left = _.get(preview, "left", "50%");
  const top = _.get(preview, "bottom", "50%");
  console.log(preview)
  const goProduction = (link) => {
    window.open("#" + link, "_blank");
  };

  const goStartScene = () => {
    // console.log("goStartScene")
    if (activeUrl === "catlike") {
      const vid = document.getElementById("canvas-video");
      vid.load();
      vid.play();
    }

    setEnterRoom(false);
  };

  const enterVisitRoom = () => {
    console.log(activeUrl)
    if (activeUrl === "catlike") {
      const vid = document.getElementById("canvas-video");
      vid.load();
      vid.play();
      console.log("play Video")
    }

    setEnterRoom(true)
  }

  const onPointClick = (id) => {
    console.log(id)
    if (id !== pointInfo.pointId) {
      setPointInfo({ prevPointId: pointInfo.pointId, pointId: id });
      setHotSpotsVisible(false);
    }
  };

  const removeSphere = () => {
    setPointInfo({ ...pointInfo, prevPointId: null });
  };

  function intersectionsFilter(intersections) {
    return intersections?.length ? [intersections[0]] : intersections;
  }

  return (
    <StyledContainer>
      {enterRoom && (
        <Canvas
          onCreated={onCreatedCanvas}
          camera={{ position: [0, 0, 0.0001], near: 0.00001, far: 10000 }}
          colorManagement={false}
          pixelRatio={window.devicePixelRatio}
          raycaster={{ filter: intersectionsFilter }}
          style={{ cursor: cursor }}
        >
          <Tour
            sphereData={sphereData}
            videos={videos}
            pointId={pointInfo.pointId}
            prevPointId={pointInfo.prevPointId}
            onPointClick={onPointClick}
            setHotSpotsVisible={setHotSpotsVisible}
            hotSpotsVisible={hotSpotsVisible}
            onSelectProduct={goProduction}
            goStartScene={goStartScene}
            setCursor={setCursor}
            removeSphere={removeSphere}
          />
          <CameraControls
            rotateSpeed={!isMobile ? -0.3 : -0.4}
            dampingFactor={!isMobile ? 0.1 : 0.15}
          />
        </Canvas>
      )}
      {!enterRoom && (
        <BeginScreen
          backgroundUrl={backgroundUrl}
          top={top}
          left={left}
          setEnterRoom={enterVisitRoom}
        />
      )}
      {/* <StyledBottomLayer>
        <Canvas camera={{ position: [0, 1000, 0] }}>
          <Monitor sphereData={sphereData} pointId={pointInfo.pointId} />
        </Canvas>
      </StyledBottomLayer> */}
      <video id="canvas-video" loop crossOrigin="anonymous" playsInline preload="true" style={{display:"none"}}>
        <source src={video1} type='video/mp4'></source>
      </video>
    </StyledContainer>
  );
};

VirtualTour.propTypes = {};

export default VirtualTour;
