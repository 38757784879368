import styled from 'styled-components'

export const StyledContainer = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // position: fixed;
  z-index: 1000;
`;

export const StyledBottomLayer = styled.div`
  bottom: 0;
  left: 0;
  width: 20vw;
  height: 20vh;
  position: absolute;
  background: rgba(100, 100, 100, 0.6);
`;

export const StyledBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  img {
    // width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
`
export const StyledSVGContainer = styled.div`
  position: absolute;
  left: ${(props) => props.left};
  top: ${(props) => props.top};

  &:hover {
    cursor: pointer;
  }
`

export const StyledSVG = styled.svg`


  .a {
    fill:#fff;
    opacity:0.4;
  }

  .b {
    fill:#0166a4;
    opacity:0.8;
  }

  .c, .d {
    fill:none;
  }

  .d {
    stroke:#fff;
  }

  .e, .f {
    stroke:none;
  }

  .f {
    fill:#fff;
  }
`