import styled from "styled-components";

export const StyledHotspot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`

export const StyledSVG = styled.svg`
  .a {
    fill:rgba(226,10,22,0.2);
    stroke:#fff;
  }

  .b {
    fill:#e20a16;
  }

  .c {
    stroke:none;
  }

  .d {
    fill:none;
  }
`

export const StyledContent = styled.div`
  color: #ffffff;

  h1 {
    font-size: 20px;
  }
`