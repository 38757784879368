import React, { useMemo } from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import * as THREE from "three";

// Components
// import HotSpot from "./HotSpot";
import HotSpot from './HotMeshSpot'

const HotSpots = ({ sphereData, pointId, goPointClick, visible, setCursor }) => {
  // const p = _.get(sphereData, pointId, null);

  // const spots = useMemo(() => {
  //   const p = _.get(sphereData, pointId, null);
  //   // console.log(p)
  //   return _.map(
  //     _.filter(sphereData, (d) => d.id !== p.id),
  //     (d) => {
  //       const offsetx = d.position[0] - p.position[0];
  //       const offsety = 0;
  //       const offsetz = d.position[1] - p.position[1];

  //       return {
  //         link: d.id,
  //         position: [offsetx, offsety, offsetz],
  //         rotation: [1.5757, 0, 3.14],
  //         size: [20, 20, 20],
  //       };
  //     }
  //   );
  // }, [pointId, sphereData]);

  const spots = useMemo(
    () => _.get(sphereData, [pointId, "hotspots"], []),
    [pointId, sphereData]
  );

  return (
    <group rotation={[0, Math.PI, 0]}>
      {_.map(spots, (d, i) => (
        <HotSpot
          key={i}
          id={_.get(d, "link", null)}
          position={_.get(d, "position", [0, 0, 0])}
          size={_.get(d, "size", [-50, 50])}
          rotation={_.get(d, "rotation", [0, 0, 0])}
          link={_.get(d, "link", 1)}
          goPointClick={goPointClick}
          visible={visible}
          setCursor={setCursor}
        />
      ))}
    </group>
  );
};

HotSpots.propTypes = {};

export default HotSpots;
