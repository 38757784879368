import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import * as THREE from "three";
import * as _ from "lodash";

// Components
import InfoSpot from "./infospot";



const InfoSpots = ({
  sphereData,
  pointId,
  visible,
  onSelectProduct,
  setCursor,
}) => {
  // const [texture, setTexture] = useState(null);

  // useEffect(() => {
  //   const loader = new THREE.TextureLoader();
  //   loader.load(HotSpotTexture, (t) => {
  //     setTexture(t);
  //   });
  // }, []);

  const spots = useMemo(
    () => _.get(sphereData, [pointId, "infospots"], []),
    [pointId, sphereData]
  );

  return (
    <>
      {_.map(spots, (d, i) => (
        <InfoSpot
          key={i}
          id={_.get(d, "link", null)}
          position={_.get(d, "position", [0, 0, 0])}
          scale={_.get(d, "size", [-50, 50])}
          rotation={_.get(d, "rotation", [0, 0, 0])}
          text={_.get(d, "title", "")}
          // goPointClick={goPointClick}
          visible={visible}
          link={_.get(d, "link", "/")}
          size={_.get(d, "size", [10, 10])}
          onSelectProduct={onSelectProduct}
          setCursor={setCursor}
          // texture={texture}
        />
      ))}
    </>
  );
};

InfoSpots.propTypes = {};

export default InfoSpots;
